<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12" lg="5">
      <CSelect
        size="sm"
        :label="$t('label.hazardClass')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        addLabelClasses="required text-right"
        :options="ImdgClassOptions"
        v-model="$v.Imdg.ImdgClass.$model"
        :is-valid="hasError($v.Imdg.ImdgClass)"
        :invalid-feedback="errorMessage($v.Imdg.ImdgClass)"
        @change="listImdgCode($event)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CSelect
        size="sm"
        :label="$t('label.imdgCode')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        addLabelClasses="required text-right"
        :disabled="!Imdg.ImdgClass"
        :options="ImdgCodeOptions"
        v-model.trim="$v.Imdg.ImdgId.$model"
        :is-valid="hasError($v.Imdg.ImdgId)"
        :invalid-feedback="errorMessage($v.Imdg.ImdgId)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CSelect
        size="sm"
        :label="$t('label.PackagingGroup')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        addLabelClasses="text-right"
        :options="PackagingGroupOptions"
        v-model.trim="$v.Imdg.PackagingGroup.$model"
        :is-valid="hasError($v.Imdg.PackagingGroup)"
        :invalid-feedback="errorMessage($v.Imdg.PackagingGroup)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CSelect
        size="sm"
        :label="$t('label.UnNumber')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        addLabelClasses="text-right"
        :disabled="!Imdg.ImdgClass"
        :options="UnNumberOptions"
        v-model.trim="$v.Imdg.UnNumber.$model"
        :is-valid="hasError($v.Imdg.UnNumber)"
        :invalid-feedback="errorMessage($v.Imdg.UnNumber)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <CTextarea
        :label="$t('label.description')"
        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
        size="sm"
        v-uppercase
        v-model.trim="$v.Imdg.DescriptionGoods.$model"
        addLabelClasses="text-right"
        rows="2"
        :is-valid="hasError($v.Imdg.DescriptionGoods)"
        :invalid-feedback="errorMessage($v.Imdg.DescriptionGoods)"
      />
    </CCol>
    <CCol sm="12" lg="5">
      <div class="col-sm-12 col-lg-4 col-form-label-sm text-right ml-2" style="text-align: left;">
        <CButton
          color="add"
          size="sm"
          class="mr-1"
          v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
          :disabled="isSubmit"
          @click="SubmitImdg()"
          >
          <CIcon name="checkAlt"/>
        </CButton>
        <CButton
          color="wipe"
          class="justify-content-end"
          size="sm"
          v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
          :disabled="isSubmit"
          @click="Clear"
          >
          <CIcon name="cil-brush-alt" />
        </CButton>
      </div>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListImdg"
        :fields="imdgfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #options="{ item }">
          <td class="text-center">
            <CButton
              color="edit"
              square
              size="sm"
              class="mr-2"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.Imdg'),
                placement: 'top-end'
                }"
              @click="EditImdg(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.Imdg'),
                placement: 'top-end',
              }"
              @click="DeleteImdg(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    Imdg: {
      id: 0,
      ImdgClass: '',
      ImdgId: '',
      PackagingGroup: '',
      UnNumber: '',
      DescriptionGoods: '',
    },
    ImdgClassList: [],
    ImdgCodeList: [],
    PackagingGroupList: [],
    UnNumberList: [],
    BlImdgGeneralList: [],
  };
}

//methods
function getService() {
  this.$emit('Loading', true);
  let requests = [
    this.$http.ejecutar("GET", "BlImdgGeneral-info"),
  ];
  Promise.all(requests)
    .then((responses) => {
      let Service = responses[0].data.data&&responses[0].data.data[0] ? responses[0].data.data[0] : {};
      //LISTAR CLASE DE PELIGRO
      this.ImdgClassList = Service.ImdgJson ?? [];
      //LISTAR GRUPO DE EMBALAJE
      this.PackagingGroupList = Service.PackagingGroupJson ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$emit('Loading', false);
    });
}

function SubmitImdg(){
  try {
    this.isSubmit = true;
    this.$v.Imdg.$touch();
    if (this.$v.Imdg.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let hazardClassData = this.Imdg.ImdgClass ? this.ImdgClassList.find((val) => val.ImdgClassId == this.Imdg.ImdgClass) : {};
    let imdgCodeData = this.Imdg.ImdgId ? this.ImdgCodeList.find( (val) =>  val.ImdgId == this.Imdg.ImdgId) : {};
    let UnNumberData = this.Imdg.UnNumber ? this.UnNumberList.find( (val) =>  val.UnNumberId == this.Imdg.UnNumber) : {};
    let PackagingGroupData = this.Imdg.PackagingGroup ? this.PackagingGroupList.find( (val) =>  val.PackagingGroupId == this.Imdg.PackagingGroup) : {};

    if (this.Imdg.id==0) {
      this.ImdgList.push({
        IdX: this.ImdgList.length > 0 ? this.ImdgList[this.ImdgList.length - 1].IdX + 1 : 1,
        BlCargoMasterImdgId: this.Imdg.BlCargoMasterImdgId, 
        ImdgCode: imdgCodeData.ImdgCod ?? '',
        ImdgClassCode: hazardClassData.ImdgClassAbbrev ?? '',
        ImdgClassId: this.Imdg.ImdgClass ?? '',
        ImdgClassName: hazardClassData  ? hazardClassData[`ImdgClassName${_lang}`]: '',
        ImdgId: this.Imdg.ImdgId ?? '',
        ImdgName: imdgCodeData  ? imdgCodeData[`ImdgName${_lang}`]: '',
        UnNumberId: this.Imdg.UnNumber ?? '',
        UnNumber: UnNumberData ? UnNumberData.UnNumber: '',
        PackagingGroupId: this.Imdg.PackagingGroup ?? '',
        PackagingGroupCode: PackagingGroupData ? PackagingGroupData.PackagingGroupCode: '',
        DescriptionOfGoodsDGS: this.Imdg.DescriptionGoods ?? '',
        Status: 1,
      })
    }else{
      this.$emit('UpdateList', this.ImdgList.map(item => {
        if (item.IdX == this.Imdg.id) {
          return {
            IdX: item.IdX,
            VisitBillOfLadingImdgId: item.VisitBillOfLadingImdgId,
            BlCargoMasterImdgId: this.Imdg.BlCargoMasterImdgId, 
            ImdgCode: imdgCodeData?.ImdgCod ?? '',
            ImdgClassCode: hazardClassData.ImdgClassAbbrev ?? '',
            ImdgClassId: this.Imdg.ImdgClass ?? '',
            ImdgClassName: hazardClassData  ? hazardClassData[`ImdgClassName${_lang}`]: '',
            ImdgId: this.Imdg.ImdgId ?? '',
            ImdgName: imdgCodeData  ? imdgCodeData[`ImdgName${_lang}`]: '',
            UnNumberId: this.Imdg.UnNumber ?? '',
            UnNumber: UnNumberData ? UnNumberData.UnNumber: '',
            PackagingGroupId: this.Imdg.PackagingGroup ?? '',
            PackagingGroupCode: PackagingGroupData ? PackagingGroupData.PackagingGroupCode: '',
            DescriptionOfGoodsDGS: this.Imdg.DescriptionGoods ?? '',
            Status: 1,
          }
        }else{
          return item;
        }
      }));
    }
    this.isSubmit = false;
    this.Clear();
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

function listImdgCode(event){
  this.Imdg.ImdgClass = event?.target?.value ?? ''; 

  this.Imdg.ImdgId = '';
  this.Imdg.UnNumber = '';
  this.ImdgCodeList = [];
  this.UnNumberList = [];

  let ImdgClasssData = this.ImdgClassList.find((val) => val.ImdgClassId == this.Imdg.ImdgClass);
  if (ImdgClasssData) {
     this.ImdgCodeList = ImdgClasssData?.ImdgJson ?? [];
     this.UnNumberList = ImdgClasssData?.UnNumberJson ?? [];
  }
}

function EditImdg(item) {
  this.Imdg = {
    id: item.IdX,
    ImdgClass: item.ImdgClassId,
    ImdgId: item.ImdgId,
    PackagingGroup: item.PackagingGroupId,
    UnNumber: item.UnNumberId,
    DescriptionGoods: item.DescriptionOfGoodsDGS,
  };
  this.$v.Imdg.$touch();
}

function DeleteImdg(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.VisitBillOfLadingImdgId) {
          this.$emit('UpdateList', this.ImdgList.map(Imdg => {
            if (Imdg.VisitBillOfLadingImdgId == item.VisitBillOfLadingImdgId) {
              return {
                ...Imdg,
                Status: 0,
              };
            } else {
              return Imdg;
            }
          }));
        }else{
          this.$emit('UpdateList', this.ImdgList.filter(e => e.IdX != item.IdX));
        }
      }
    });
}

function Clear() {
  this.Imdg.id = 0;
  this.Imdg.ImdgClass = '';
  this.Imdg.ImdgId = '';
  this.Imdg.PackagingGroup = '';
  this.Imdg.UnNumber = '';
  this.Imdg.DescriptionGoods = '';
  this.$v.Imdg.$reset();
}

function Reset() {
  this.Imdg = {
    id: 0,
    ImdgClass: '',
    ImdgId: '',
    PackagingGroup: '',
    UnNumber: '',
    DescriptionGoods: '',
  };
  this.ImdgClassList = [];
  this.ImdgCodeList = [];
  this.PackagingGroupList = [];
  this.UnNumberList = [];
}

//computed
function ImdgClassOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ImdgClassList.map(function(e){
    chart.push({
      value: e.ImdgClassId, 
      label: e['ImdgClassName'+_lang] ?  e.ImdgClassAbbrev + ' - ' + e['ImdgClassName'+_lang] : 'N/A'
    })    
  })
  return chart;
}

function ImdgCodeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ImdgCodeList.map(function(e){
    chart.push({
      value: e.ImdgId, 
      label: e.ImdgCod
    })    
  })
  return chart;
}

function PackagingGroupOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingGroupList.map(function(e){
    chart.push({
      value: e.PackagingGroupId, 
      label: e.PackagingGroupCode,
    })    
  })
  return chart;
}

function UnNumberOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnNumberList.map(function(e){
    chart.push({
      value: e.UnNumberId, 
      label: e.UnNumber
    })    
  })
  return chart;
}

function computedListImdg() {
  let List = this.ImdgList.filter(item => item.Status != 0);
  return  List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      ImdgClassAbbrev: item.ImdgClassCode ?? 'N/A',
      ImdgCod: item.ImdgCode ?? 'N/A',
      PackagingGroupCod: item.PackagingGroupCode ?? 'N/A',
      ComputedUnNumber: item.UnNumber ?? 'N/A',
      ComputedDescriptionOfGoodsDGS: item.DescriptionOfGoodsDGS ?? 'N/A',
    }
  });
}

function imdgfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'ImdgClassAbbrev', label: this.$t('label.hazardClass'),  _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
    { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
    { key: 'PackagingGroupCod', label: this.$t('label.PackagingGroup'), _style:'width:18%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'ComputedUnNumber', label: this.$t('label.UnNumber'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'ComputedDescriptionOfGoodsDGS', label: this.$t('label.description'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'options', label: '', _style: 'width:5%; min-width:90px; text-align:center;', sorter: false, filter: false },
  ];
}

export default {
  name: 'Imdg-tab',
  components: {
  },
  data,
  props: {
    ImdgList: {
      type: Array,
      required: true,
      default: () => [],
    },
    isEdit: Boolean,
    showModal: Boolean,
    Tab: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations();
  },
  methods: {
    getService,
    SubmitImdg,
    listImdgCode,
    EditImdg,
    DeleteImdg,
    Clear,
    Reset,
  },
  computed:{
    ImdgClassOptions,
    ImdgCodeOptions,
    PackagingGroupOptions,
    UnNumberOptions,
    computedListImdg,
    imdgfields,
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.Reset();
        this.$v.$reset();
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.getService();
      }
    },
  }
};
</script>